<template>
    <div>
        <st-filters-pagination
            stateModule="paymentRegistry/list"
            :filters="filters"
            :total="total"
            @change="doFilter"
            :header="$t('PAYMENT_REGISTRY.LIST.TITLE')"
            :clearButtonInPrimaryFilters="true"
        >
            <template #filters-toolbar>
                <slot name="toolbar"></slot>
            </template>
            <slot></slot>
        </st-filters-pagination>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { ApplicationModel } from '@/modules/applications/models/application-model';

    const { fields, statuses } = ApplicationModel;

    const filterSchema = new FilterSchema([
        fields.identification_number,
        fields.beneficiary,
        fields.cadastral_number,
        fields.internal_status,
        fields.created_date,
        fields.territorial_administrative_unit_id,
        fields.application_type_name,
    ]);

    export default {
        name: 'PaymentRegistryListFilter',
        data() {
            return {
                filters: filterSchema.fields,
                fields
            };
        },
        computed: {
            ...mapGetters({
               total: 'paymentRegistry/list/total',
            }),
        },
        created() {
            this.fields.internal_status.options = this.staffStatusOptions(statuses);
        },
        methods: {
            ...mapActions({
                doFetch: 'paymentRegistry/list/doFetch',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            async doFilter(params) {
                this.params = params;
                this.doFetch(params);
            },
            staffStatusOptions(statusOptions) {
                return statusOptions.filter(option => option.name !== 'draft')
                                    .sort((a, b) => a.sortkey - b.sortkey );
            }
        },

    };
</script>
