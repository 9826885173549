<template>
    <div>
        <st-button
            variant="primary"
            :callback="calculateTax"
            v-can:view="'taxCalculator'"
        >
            <i class="fas fa-calculator"></i>
            {{ $t('PAYMENT_REGISTRY.CALCULATE_TAX') }}
        </st-button>
        <st-button
            v-if="false"
            variant="secondary"
            :callback="addPaymentOrder"
            v-can:create="'paymentOrder'"
        >
            <i class="fas fa-credit-card"></i>
            {{ $t('PAYMENT_REGISTRY.ADD_PAYMENT_ORDER') }}
        </st-button>
        
    </div>
</template>

<script>
    export default {
        name: 'PaymentRegistryListToolbar',
        methods: {
            addPaymentOrder() {
                this.$router.push({ name: 'paymentOrder'})
            },
            calculateTax() {
                this.$router.push({ name: 'taxCalculator'});
            }
        }
    };
</script>
