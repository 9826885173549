<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :serverSideSorting="true"
            @view="onView"
            @download="onDownload"
            responsive="sm"
            hover
            stateModule="paymentRegistry/list"
        >
        </st-data-table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ApplicationModel } from '@/modules/applications/models/application-model';

const { fields } = ApplicationModel;
export default {
    name: 'PaymentRegistryListTable',
    data() {
        return {
            presenter: ApplicationModel.presenter,
            actions: [
                {
                    name: 'view',
                    icon: 'eye',
                    tooltipText: this.$t('GENERAL.ACTIONS.VIEW'),
                    customIcon: false,
                    type: 'primary'
                },
                // {
                //     name: 'download',
                //     icon: 'file-download',
                //     tooltipText: this.$t('GENERAL.ACTIONS.DOWNLOAD'),
                //     customIcon: false,
                //     type: 'primary'
                // }
            ],
        };
    },
    computed: {
        ...mapGetters({
            rows: 'paymentRegistry/list/rows',
        }),
        fields() {
            return [
                fields.identification_number.extendField({ sortable: true}),
                fields.application_date.extendField({ sortable: true}),
                fields.internal_status.extendField({ labelType: true, sortable: true }),
                fields.application_type_name.extendField({ sortable: true}),
                fields.beneficiary.extendField({ customField: true, portalTarget: 'beneficiary-custom-field'}),
                fields.locality.extendField({ customField: true, portalTarget: 'locality-custom-field'}),
                fields.total_tax,
            ]
        }
    },
    methods: {
        onView(data) {
            if (!data?.item?.id) return;
            // Open in new tab
            let routeData = this.$router.resolve({
                name: 'applicationView',
                params: {
                    id: data.item.id,
                },
                query: { goToSection: 'applicationSummary'}
            });
            window.open(routeData.href, '_blank');
        },
        onDownload() {
            // TODO: TBD
        }
    },
};
</script>
